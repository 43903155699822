<template>
  <div
    ref="sticker"
    class="sticker__ani"
  />
</template>

<script>
import { onMounted, ref } from "@/helper/vue.js";;

import lottie from 'lottie-web'

export default {
  name: "AnimateSvg",
  props: {
    fileName: String,
  },
  setup(props) {
    const sticker = ref()

    onMounted(() => {
      lottie.loadAnimation({
        container: sticker.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require(`@/assets/stickers/${props.fileName}.json`)
      })
    })

    return {
      sticker
    }
  }
}
</script>

<style lang="scss" scoped>
.sticker__ani {
  width: 100%;
  overflow: hidden;
}
</style>